import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { __DEV__ } from '@apollo/client/utilities/globals'
import { Theme } from '@radix-ui/themes'
import { useBlockChainBalance } from 'features/approved-balance/hooks/use-blockchain-balance'
import { ErrorPage } from 'pages/error'
import { ErrorBoundary } from 'react-error-boundary'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useLoadingOverlay } from 'shared/hooks/use-loading-overlay'
import { useOffline } from 'shared/hooks/use-offline'
import { useUserMetadata } from 'shared/hooks/use-user-metadata'
import { useVerifyUser } from 'shared/hooks/use-verify-user'
import { GlobalLoaderOverlay } from 'shared/ui'
import { OfflineModal } from 'shared/ui/offline-modal/offline-modal'
import { AppRoutes } from './app-routes'
import { SKELETON_CONFIG, THEME_CONFIG } from './constants'
import '@radix-ui/themes/styles.css'
import 'shared/styles/global.scss'
// TODO: this import doesn't work, investigate
import 'react-loading-skeleton/dist/skeleton.css'

if (__DEV__) {
  loadDevMessages()
  loadErrorMessages()
}

const App: React.FC = () => {
  useVerifyUser()
  useOffline()
  useUserMetadata()
  useLoadingOverlay()
  useBlockChainBalance()

  return (
    <Theme {...THEME_CONFIG}>
      <SkeletonTheme {...SKELETON_CONFIG}>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <AppRoutes />

          {/* TODO: move modals and overlays to main layout */}
          <GlobalLoaderOverlay />
          <OfflineModal />
        </ErrorBoundary>
      </SkeletonTheme>
    </Theme>
  )
}

export default App
